exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applyasdev-index-js": () => import("./../../../src/pages/applyasdev/index.js" /* webpackChunkName: "component---src-pages-applyasdev-index-js" */),
  "component---src-pages-applyasdevresponse-index-js": () => import("./../../../src/pages/applyasdevresponse/index.js" /* webpackChunkName: "component---src-pages-applyasdevresponse-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-dev-index-js": () => import("./../../../src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-hiredeveloperresponse-index-js": () => import("./../../../src/pages/hiredeveloperresponse/index.js" /* webpackChunkName: "component---src-pages-hiredeveloperresponse-index-js" */),
  "component---src-pages-hiretalentone-index-js": () => import("./../../../src/pages/hiretalentone/index.js" /* webpackChunkName: "component---src-pages-hiretalentone-index-js" */),
  "component---src-pages-hiretalenttwo-index-js": () => import("./../../../src/pages/hiretalenttwo/index.js" /* webpackChunkName: "component---src-pages-hiretalenttwo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

